<template>
  <form @submit.prevent="save()">
    <div class="row">
      <div class="col-12 col-lg-6">
        <div class="form-group">
          <label for="firstname">Vorname</label>
          <input
            type="text"
            class="form-control"
            name="firstname"
            v-model.trim="form.firstname"
            required
          />
        </div>
      </div>
      <div class="col-12 col-lg-6">
        <div class="form-group">
          <label for="lastname">Nachname</label>
          <input
            type="text"
            class="form-control"
            name="lastname"
            v-model.trim="form.lastname"
            required
          />
        </div>
      </div>
      <div class="col-12 col-lg-6">
        <div class="form-group">
          <label for="email">E-Mail</label>
          <input type="email" class="form-control" name="email" v-model.trim="form.email" required />
        </div>
      </div>
      <div class="col-12 col-lg-6">
        <div class="form-group">
          <label for="password">
            {{
            trainer ? 'Neues Passwort' : 'Passwort'
            }}
          </label>
          <input
            type="password"
            class="form-control"
            name="password"
            v-model.trim="form.password"
            :required="!trainer"
          />
        </div>
      </div>
      <div class="col-12 col-lg-3">
        <div class="form-group">
          <label for="hourly-wage">Stundenlohn</label>
          <input
            type="number"
            step="0.01"
            min="0"
            class="form-control"
            name="hourly-wage"
            v-model.number="form.hourlyWage"
            required
          />
        </div>
      </div>
      <div class="col-12 col-lg-3">
        <div class="form-group">
          <label for="vatkey">Ust.-Relevanz</label>
          <select name="vatkey" v-model="form.vatkey" class="form-control">
            <option :value="null">Nein</option>
            <option :value="9">Ja</option>
          </select>
        </div>
      </div>
      <div class="col-12 col-lg-6">
        <div class="form-group">
          <label for="creditors">Konto (Kreditoren)</label>
          <div class="form-group">
            <input type="text" class="form-control" v-model.trim="newCreditor.id" />
            <select v-model="newCreditor.holding" class="mt-2 form-control form-control-sm">
              <option :value="null" disabled>Holding auswählen...</option>
              <option
                v-for="holding in holdings"
                :key="holding.id"
                :value="holding"
              >{{ holding.name }}</option>
            </select>
          </div>
          <button type="button" class="btn btn-link" @click="addCreditor()">+ Kreditor hinzufügen</button>
          <ul>
            <li v-for="creditor in form.creditors" :key="creditor.id">
              {{ creditor.id }} |
              {{ creditor.holding ? creditor.holding.name : '' }}
              <span
                class="fa fa-fw fa-trash"
                @click="removeCreditor(creditor)"
              ></span>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-12 col-lg-6">
        <div class="form-group">
          <label for="iban">IBAN</label>
          <input type="text" class="form-control" name="iban" v-model.trim="form.iban" />
        </div>
      </div>
      <div class="col-12 col-lg-6">
        <div class="form-group">
          <label for="bic">BIC</label>
          <input type="text" class="form-control" name="bic" v-model.trim="form.bic" />
        </div>
      </div>
      <div class="col-12 col-lg-3">
        <div class="form-group">
          <label for="permanent-employment">Festanstellung</label>
          <select
            name="permanent-employment"
            class="form-control"
            v-model="form.permanentEmployment"
          >
            <option :value="false">Nein</option>
            <option :value="true">Ja</option>
          </select>
        </div>
      </div>
    </div>
    <button type="submit" class="btn btn-dark" :disabled="submitting">Speichern</button>
    <div class="alert alert-danger mt-3" v-if="errMsg">{{ errMsg }}</div>
  </form>
</template>

<script>
import { firestore, functions } from '../firebase';

const createTrainer = functions.httpsCallable('httpsCreateTrainer');
const updateTrainer = functions.httpsCallable('httpsUpdateTrainer');

export default {
  data() {
    return {
      holding: '',
      form: {
        firstname: '',
        lastname: '',
        email: '',
        password: '',
        vatkey: null,
        hourlyWage: 0,
        creditors: [],
        iban: '',
        bic: '',
        permanentEmployment: false,
      },
      holdings: [],
      newCreditor: {
        id: '',
        holding: null,
      },
      trainer: null,
      submitting: false,
      errMsg: '',
    };
  },
  async created() {
    const id = this.$route.params.id;

    const holdingsSnapshot = await firestore.collection('holdings').get();

    this.holdings = holdingsSnapshot.docs.map((doc) => {
      return {
        id: doc.id,
        ...doc.data(),
      };
    });

    if (id) {
      const trainerSnapshot = await firestore
        .collection('trainers')
        .doc(id)
        .get();

      this.trainer = { id: trainerSnapshot.id, ...trainerSnapshot.data() };
      this.form = { ...this.form, ...this.trainer };

      delete this.form.id;
      delete this.form.password;
    }
  },
  methods: {
    async save() {
      this.submitting = true;
      this.errMsg = '';

      try {
        if (this.trainer) {
          this.form = {
            ...this.form,
            uid: this.trainer.id,
          };

          await updateTrainer({
            ...this.form,
          });
        } else {
          await createTrainer({ ...this.form });
        }

        this.$router.push({
          name: 'trainers',
        });
      } catch (err) {
        this.errMsg = err.message;

        throw err;
      } finally {
        this.submitting = false;
      }
    },
    addCreditor() {
      if (this.newCreditor.id && this.newCreditor.holding) {
        this.form = {
          ...this.form,
          creditors: [...this.form.creditors, this.newCreditor],
        };

        this.newCreditor = {
          id: '',
          holding: null,
        };
      }
    },
    removeCreditor(creditor) {
      this.form = {
        ...this.form,
        creditors: this.form.creditors.filter((c) => creditor !== c),
      };
    },
  },
};
</script>
